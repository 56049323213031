// https://openlayers.org/en/latest/examples/popup.html
$background: #263238;
.ol-popup {
    position: absolute;
    background-color: $background;
    color: white;
    a{
        color: white;
    }
    font-size: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid transparent;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
    &:after, &:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:after {
        border-top-color: $background;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
    }
    &:before {
        border-top-color: $background;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }
}
.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 5px;
    &:after {
        color: transparent; // (removed)
        content: "✖";
    }
}
