// bootstrap panels/styles for vue-query-builder
#export, #download{
    .sidebar-header{
        margin-bottom: 10px;
    }
}
#filter, #export, #download, .vue-query-builder{
    .rule-actions > .form-group{
        display: flex;
        > select, > button {
            display: inline-flex;
        }
        > select {
            min-width: 0; // firefox oddity
        }
    }
    .vqb-rule > .form-group{
        > label{
            display: block;
        }
        > select, > input{
            width: auto;
            display: inline-block;
        }
    }
    * {
        box-sizing: border-box;
    }
    label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 700;
    }
    button.close {
        -webkit-appearance: none;
        padding: 0;
        cursor: pointer;
        background: 0 0;
        border: 0;
    }
    .close {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        filter: alpha(opacity=20);
        opacity: .2;
    }
    .panel{
        position: relative;
    }
    .panel-body {
        padding: 15px;
    }
    .form-group {
        margin-bottom: 15px;
    }
    .panel-default>.panel-heading {
        color: #333;
        background-color: #f5f5f5;
        border-color: #ddd;
    }
    .panel-heading {
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }
    .btn-default {
        color: #555;
        background-color: #fff;
        border-color: #ccc;
    }
    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }
    button, input, optgroup, select, textarea {
        margin: 0;
        font: inherit;
        color: inherit;
    }
    button{
        &[disabled]{
            opacity: 0.5;
        }
    }
}

#filter{
    &.sidebar-pane{
        padding-left: 0;
        padding-right: 0;
        .sidebar-header{
            padding-left: 40px;
        }
    }
    .filter-layer{
        border-bottom: 1px solid $active-color;
    }
    .header{
        padding: 10px 40px 10px 20px;
        position: relative;
        cursor: pointer;
        &:hover{
            background-color: $highlight-color;
        }
        svg{
            position: absolute;
            right: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
        }
    }
    .content{
        overflow-y: hidden;
        overflow-x: visible;
        max-height: 0;
        transition: max-height ease-in-out .15s, max-height ease-in-out .15s;
    }
    .filter-utilities-container {
        padding: 20px 14px 0 14px;
        .centered-single-button {
            width: 100%;
            border-color: #333;
        }
        .centered-single-button:not(:first-child) {
            margin-top: 10px;
        }
    }
    div.expanded{
        .header{
            color: white;
            background-color: $hover-color;
        }
        .content{
            max-height: 60vh;
            overflow-y: scroll;
        }
    }
}
