@import "popup";
@import "lds-ring";

[v-cloak] {
  display: none;
}

html,
body {
  font-family: Tahoma, Arial, sans-serif;
  margin: 0;
  height: 100%;
  a:link {color: inherit;}
  a:visited {color: inherit;}
  a:hover {color: blue;}
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

$active-color: rgba(69, 90, 100, 1);
$hover-color: adjust-color($active-color, $alpha: -0.4);
$background-color: adjust-color($active-color, $alpha: -0.5);
$highlight-color: adjust-color($active-color, $alpha: -0.7);

#map .ol-control button {
  background-color: $background-color;
}

/*
.ol-attribution {
  bottom: unset !important;
  top: 0.5em;
}
*/

.loader {
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .loader-text {
    color: white;
    text-shadow: #000 0px 0px 2px;
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#splashback {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #899499;
  z-index: 2009;
  opacity: 0.9;
}

#splash {
  position: absolute;
  $width: 740px;
  $height: ($width * 0.98);
  width: $width;
  height: $height;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  $padding: ($width / 20);
  padding: $padding;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  background-color: #eef1f2;
  margin-top: -($height/2);
  margin-left: -($width/2);
  background-image: url(../img/octopus-white.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 95% 15%;
  z-index: 2010;
  font-size: 15px;
  line-height: 1.4;
  color: #4f4c4c;
  .close {
    color: #bdbcbc;
    position: absolute;
    top: $padding/2;
    right: $padding/2;
    font-size: 30px;
    line-height: 20px;
    cursor: pointer;
  }
  h1 {
    margin-top: 0;
  }
  .creative-commons {
    background-image: url(../img/cc.svg);
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: -1% 0;
    padding-left: 20%;
    font-size: 12px;
    padding-bottom: 5%;
  }
  .divider {
    width: 60%;
    height: 1px;
    content: " ";
    background-color: #918f90;
    display: block;
    margin-left: 20%;
  }
  .logos {
    display: flex;
    justify-content: center;
    div {
      width: 55%;
      position: relative;
      img {
        width: 90%;
        position: absolute;
        bottom: 0;
      }
    }
    position: absolute;
    bottom: $padding;
    width: $width - ($padding * 1);
  }
}

#sidebar.sidebar {
  .sidebar-pane {
    .sidebar-header {
      background-color: $active-color;
      .sidebar-close {
        line-height: 42px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
    > p {
      background-color: #cfd8dc;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    }
  }
  .sidebar-tabs {
    background-color: $background-color;
    > ul {
      > li {
        &:hover {
          background-color: $hover-color;
        }
        &.active {
          background-color: $active-color;
        }
        img {
          padding: 10px;
          width: 50%;
        }
      }
    }
  }
  .sidebar-close {
    img {
      padding: 10px;
      height: 50%;
    }
  }
  .sidebar-content {
    input[type="number"] {
      width: 10em;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 5px;
    }
    button {
      &:hover {
        background-color: rgba(69, 90, 100, 0.2);
        cursor: pointer;
        border-color: #777;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }
}

#logo {
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  width: 80px;
  max-height: 25vw;
  max-width: 25vw;
  height: 100px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-image: url(../img/octopus-white.svg);
  background-position: 50% 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  pointer-events: none;
  &.with-border {
      background-color: $background-color;
    border-radius: 50px;
    padding: 0 12px;
  }
}

#popup-content {
  max-width: 85vw;
  max-height: 45vh;
  overflow: scroll;
  table {
    text-align: left;
    $background: #455a64;
    border: 1px solid $background;
    $radius: 5px;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    caption {
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
      padding: 5px;
      text-align: left;
      font-weight: bold;
      background: $background;
      color: #fff;
    }
    thead {
      background: #78909c;
    }
    th,
    td {
      padding: 5px 5px;
      white-space: nowrap;
    }
    tr {
      &.odd {
        background: #37474f;
      }
    }
    + table {
      margin-top: 10px;
    }
  }
}

@import "query-builder";

#download {
  textarea.form-control {
    height: 5em;
  }
  .checkbox-group {
    max-height: 25vh;
    overflow-y: scroll;
  }
  .checkbox-label {
    font-weight: normal;
    display: block;
  }
}

#export {
  select {
    margin-bottom: 10px;
  }
}

#app {
  #map {
    .ol-scale-line {
      background-color: rgba(69, 90, 100, 1);
      opacity: 0.5;
    }
  }
}
